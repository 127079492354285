<template>
  <div id="app">
    <div
      class="header"
      :style="{ background: 'rgba(0, 0, 0,' + toped + ')' }"
    >
      <header-part ref="header"/>
    </div>
    
    <router-view  />
    <footer-part ref="footer"/>
    <div id="components-back-top-demo-custom">
      <a-back-top>
        <div class="ant-back-top-inner">
          <a-icon type="arrow-up" />
        </div>
      </a-back-top>
    </div>
  </div>
</template>
<script>
import HeaderPart from "@/components/header/index";
import FooterPart from "@/components/footer/index";
export default {
  components: {
    HeaderPart,
    FooterPart,
  },
  data() {
    return {
      toped: 0.5,
      topHeight: 0,
      leftHeight: 0,
      selectIndex: 0,
      viewHeight: 0,
      visible:false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle);
  },
  methods: {
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      let left = document.body.clientWidth; // 获取页面滚动高度
      this.toped = (top / left).toFixed(1) * 1 + 0.5;
      this.topHeight= top
      this.leftHeight= left
    },
    changeContent(index) {
      this.selectIndex = index;
    },
    changeVisible(){
      this.visible = !this.visible
    },
    fatherMethod(index){
      this.$refs.header.turnTo(index)
    },
    fatherShow(){
      this.$refs.header.showPhoneNum()
    },
    useFatherFooterMath(index){
      this.$refs.footer.useFatherMethod(index)
    },
  },
};
</script>

<style scoped>
#app {
  position: relative;
  height: 100%;
  min-width: 1440px;
  /* overflow-x: auto; */
  width: 100%;
}
#components-back-top-demo-custom .ant-back-top {
  bottom: 250px;
  right: 50px;
}
#components-back-top-demo-custom .ant-back-top-inner {
  font-size: 15px;
  color: #747F92;
  padding: 10px 13px;
  background: #fff;
}
.header {
  position: fixed;
  height: 75px;
  width: 100%;
  z-index: 100;
}
.lotus {
  position: absolute;
  left: 368px;
  top: -87px;
  width: 88px;
  height: 87px;
}
.lotus-bottom {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 250px;
  height: 130px;
}
.center {
  margin: 30px 15%;
  position: relative;
  min-height: 900px;
  width: 70%;
}
.navigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 249px;
}
.navigationFixed {
  position: fixed;
  top: 120px;
  left: 15%;
  width: 249px;
}
.navigationHeader {
  width: 100%;
  height: 56px;
  line-height: 56px;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  background: #30999d;
}
.navigationItem {
  font-size: 16px;
  text-align: center;
  height: 56px;
  line-height: 56px;
  background: #f6f6f5;
  cursor: pointer;
}
.lineBox {
  height: 1px;
  width: 100%;
  background: #eeeeed;
}
.navigationSelected {
  background: #fff;
  color: #30999d;
  font-weight: 500;
  border-left: 6px solid #30999d;
  border-right: 6px solid #fff;
}
.mainContent {
  padding-left: 249px;
}
</style>
