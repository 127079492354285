<template>
  <div class="footerView">
    <div style="display: flex">
      <div style="width: 50%">
        <div style="width: 224px; text-align: center">
          <img-item :styleObj="companyIcon" imgName="编组" />
          <div class="companyName textOpacity Light">
            多创信息科技（泉州）有限公司
          </div>
        </div>
      </div>
      <div style="width: 50%; display: flex; justify-content: space-between">
        <div class="footerRoute">
          <div style="margin-bottom: 20px; display: flex">
            <div :class="selectIndex == 0 ? '' : 'textOpacity Light'" @click="useFatherMethod(0)">
              首页
            </div>
            <div :class="selectIndex == 1 ? '' : 'textOpacity Light'" @click="useFatherMethod(1)">
              经典案例
            </div>
          </div>
          <div style="display: flex">
            <div :class="selectIndex == 2 ? '' : 'textOpacity Light'" @click="useFatherMethod(2)">
              关于我们
            </div>
            <div class="textOpacity Light" @click="useFatherShow()">
              联系我们
            </div>
          </div>
        </div>
        <div style="width: 101px; text-align: center;font-size:14px;line-height;19px;">
          <img-item :styleObj="QrCode" imgName="二维码" />
          <div class="textOpacity Light">扫二维码</div>
          <div class="textOpacity Light">关注我们的动态</div>
        </div>
      </div>
    </div>
    <div style="
          width: 100%;
          text-align: center;
          margin-top: 30px;
          color: rgba(255, 255, 255, 0.5);
        ">
      <div @click="linkto" style="cursor: pointer">
        备案号：闽ICP备2020021725号-1
      </div>
      <div>Copyright 2020 DUOCHUANG ALL Rights Reserved</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectIndex: 0,
      QrCode: {
        'width': '95px',
        'height': '95px',
        'margin-bottom': '13px'
      },
      companyIcon:{
        'width': '158px',
        'height': '98px'
      }
    };
  },
  mounted() { },
  methods: {
    useFatherMethod(index) {
      this.selectIndex = index
      // 注意，我在用的过程中使用this.$parent方法时找不到父组件的方法，无意间看到这篇文章https://segmentfault.com/a/1190000017030948 说是ui框架的组件包裹了子组件导致了方法未定义的问题
      this.$parent.fatherMethod(index)
    },
    useFatherShow() {
      // 注意，我在用的过程中使用this.$parent方法时找不到父组件的方法，无意间看到这篇文章https://segmentfault.com/a/1190000017030948 说是ui框架的组件包裹了子组件导致了方法未定义的问题
      this.$parent.fatherShow()
    },
    linkto() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')

    }
  },
};
</script>

<style scoped>
.footerView {
  width: 100%;
  color: #fff;
  padding: 53px 150px 0;
  background: #16181b;
}


.companyName {
  font-size: 15px;
  height: 20px;
  line-height: 20px;
  margin-top: 16px;
}

.footerRoute {
  font-size: 15px;
  line-height: 20px;
  margin-top: 20px;
}

.footerRoute div div {
  margin-right: 70px;
  width: 65px;
  height: 20px;
  text-align: justify;
  cursor: pointer;
}

.footerRoute div div:after {
  content: "";
  padding-left: 100%;
  display: inline-block;
}

.textOpacity {
  opacity: 0.5;
}
</style>
