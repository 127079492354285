<template>
  <div>
    <div class="headerView">
      <img-item :styleObj="{width: '210px',height: '25px'}" imgName="编组 (1)" />
      <div class="headerRightPart">
        <div
          :style="{ color: pathIndex == 0 ? '#2B7BED' : '' }"
          :class="pathIndex == 0 ? 'Medium' : ''"
          @click="turnClick(0)"
        >
          首页
        </div>
        <div
          :style="{ color: pathIndex == 1 ? '#2B7BED' : '' }"
          :class="pathIndex == 1 ? 'Medium' : ''"
          @click="turnClick(1)"
        >
          案例
        </div>
        <div
          :style="{ color: pathIndex == 2 ? '#2B7BED' : '' }"
          :class="pathIndex == 2 ? 'Medium' : ''"
          @click="turnClick(2)"
        >
          关于我们
        </div>
        <div class="contactBox Medium" @click="showPhoneNum">联系我们</div>
      </div>
    </div>
    <a-modal
      v-model="visible"
      @ok="showPhoneNum"
      :footer="null"
      style="text-align: center"
    >
      <div class="showContactTitle Medium">联系我们</div>
      <div class="showContactText">联系我们，获得更专业的服务！</div>
      <div
        class="showContactTel Medium"
        data-clipboard-text="13661377101"
        @click="copy"
      >
        电话 ： 13661377101
      </div>
      <div class="showContactTelUnder">点击直接复制电话号码</div>
    </a-modal>
  </div>
</template>
<script>
import Clipboard from "clipboard";
export default {
  data() {
    return {
      visible: false,
      pathIndex: 0,
      navigationList: [
        {
          path: "/pc/home",
        },
        {
          path: "/pc/case",
        },
        {
          path: "/pc/aboutUs",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    showModel() {
      this.$parent.changeVisible();
    },
    showPhoneNum() {
      this.visible = !this.visible;
    },
    turnClick(index) {
      this.$parent.useFatherFooterMath(index)
    },
    turnTo(index) {
      this.pathIndex = index;
      this.$router.push(this.navigationList[index].path);
    },
    copy() {
      var clipboard = new Clipboard(".showContactTel");
      clipboard.on("success", (e) => {
        this.$message.success('联系方式已复制')
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style scoped>
.headerView {
  margin: 0 50px 0 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerRightPart {
  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.headerRightPart div {
  margin-left: 45px;
}
.contactBox {
  padding: 12px 30px;
  background: #2b7bed;
  border-radius: 23px;
}
.showContactTitle {
  margin: 24px 0 8px;
  font-size: 27px;
  color: #000000;
  line-height: 38px;
  letter-spacing: 2px;
}
.showContactText {
  font-size: 15px;
  color: #333333;
  line-height: 20px;
  letter-spacing: 1px;
}
.showContactTel {
  width: 345px;
  height: 75px;
  background: #f5f6fb;
  border-radius: 15px;
  margin: 23px auto 8px;
  font-size: 23px;
  color: #2b7bed;
  cursor: pointer;
  line-height: 75px;
}
.showContactTelUnder {
  font-size: 14px;
  color: #9a9a9a;
  line-height: 19px;
  letter-spacing: 1px;
}
</style>
